@import url('https://fonts.googleapis.com/css2?family=Jost:wght@200;300;400;500&family=Red+Hat+Display:wght@300;400;500;600;700;800&family=Roboto:ital,wght@0,100;0,300;1,100&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Jost', 'Red Hat Display', 'Roboto',
   'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
